import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '..';
import jwt_decode from 'jwt-decode';
import { $authHost } from './index'; // Подключаем $authHost для работы с API

const WsAPI = observer(() => {
    const { user } = useContext(Context);

    useEffect(() => {
        let socket = null;

        const setupWebSocket = (token) => {
            socket = new WebSocket(`wss://scraplands.ru:4083?token=${token}`);

            socket.onopen = () => {
                console.log('Соединение установлено');
            };

            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data && data.log) {
                    const newLogData = {
                        log: data.log,
                        data: [data.data]
                    };
                    user.setLog(newLogData);
                    //console.log(data)
                } else if (data && data.player_info) {
                    const newPlayerData = {
                        player_info: data.player_info,
                        data: [data.data]
                    };
                    user.setPlayerInfo(newPlayerData);
                }
            };

            socket.onclose = () => {
                console.log('Соединение закрыто');
            };

            socket.onerror = async (error) => {
                console.error('Ошибка WebSocket', error);

                // Если ошибка связана с истекшим токеном, попробуйте его обновить
                if (error.message.includes('401')) {
                    try {
                        const { data } = await $authHost.post('api/v1/refresh.php', {}, { withCredentials: true });
                        const newToken = data.access_token;
                        localStorage.setItem('token', newToken);

                        // Переподключаем WebSocket с новым токеном
                        setupWebSocket(newToken);
                    } catch (refreshError) {
                        console.error('Ошибка обновления токена', refreshError);
                    }
                }
            };
        };

        const validateAndConnect = () => {
            const token = localStorage.getItem('token');

            if (token) {
                try {
                    const decoded = jwt_decode(token);
                    const currentTime = Math.floor(Date.now() / 1000);

                    // Проверка, что токен не истек
                    if (decoded.exp > currentTime) {
                        setupWebSocket(token);
                    } else {
                        // Если токен истек, попытка обновить токен
                        $authHost.post('api/v1/refresh.php', {}, { withCredentials: true })
                            .then(({ data }) => {
                                const newToken = data.access_token;
                                localStorage.setItem('token', newToken);
                                setupWebSocket(newToken);
                            })
                            .catch((error) => {
                                console.error('Ошибка обновления токена', error);
                            });
                    }
                } catch (err) {
                    console.error('Ошибка декодирования токена', err);
                }
            } else {
                console.log('Токен не найден, соединение не будет установлено');
            }
        };

        validateAndConnect();

        // Закрытие WebSocket соединения при размонтировании компонента
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [user]);

    return null;
});

export default WsAPI;
