import {$authHost, $host} from ".";
// eslint-disable-next-line
import jwt_decode from "jwt-decode";


export const login = async (username, password) => {
    const {data} = await $host.post('api/v1/login.php', {username, password},{withCredentials: true});
    localStorage.setItem('token', data.access_token);
    return data;
};

export const registration = async (username, email, password) => {
    const {data} = await $host.post('api/v1/registration.php', {username, email, password},{withCredentials: true});
    localStorage.setItem('token', data.access_token);
    return data;
};

export const confirmation = async (hash) => {
    const {data} = await $host.post('api/v1/confirmation.php', {hash},{withCredentials: true});
    return data;
};

export const check = async () => {
    const {data} = await $authHost.post('api/v1/refresh.php', {},{withCredentials: true});
    localStorage.setItem('token', data.access_token);
    return data;
};

export const logout = async () => {
    const {data} = await $authHost.post('api/v1/logout.php', {});
    return data;
};

export const usersList = async () => {
    const {data} = await $authHost.post('api/v1/index.php', {});
    return data;
};

export const userInfo = async () => {
    const {data} = await $authHost.get('api/v1/userInfo.php', {});
    return data;
};

export const userConnect = async (serverId, connect) => {
    const {data} = await $authHost.post('api/v1/userConnect.php', {serverId, connect},{withCredentials: true});
    return data;
};