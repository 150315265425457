import React, {useContext, useEffect, useRef, useState} from "react";
import BreadCrumbs from "../components/Breadcrumbs";
import {useParams} from "react-router-dom"; // Чтобы получить server_id из параметров маршрута
import {observer} from "mobx-react-lite";
import Container from "@mui/material/Container";
import {Context} from "..";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {SERVERS_ROUTE} from "../utils/consts";
import {sendRcon} from "../http/ServerAPI";

const Log = observer(() => {
    const {server_id} = useParams(); // Получаем server_id из URL

    const {user} = useContext(Context); // Доступ к userStore через контекст
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [messageLength, setMessageLength] = useState(0);
    const [chatMessages, setChatMessages] = useState([]); // Состояние для сообщений чата
    const [message, setMessage] = useState(""); // Состояние для отправки сообщения

    const hrefs = [
        { href: SERVERS_ROUTE, name: "Список серверов" },
    ];

    const chatEndRef = useRef(null); // Ссылка на конец списка сообщений

    useEffect(() => {
        if (user.userName) {
            setName(user.userName);
            setRole(user.role);
        } else {
            setName('');
            setRole('');
        }
    }, [user.userName, user.role]);

    useEffect(() => {
        // Получаем данные для чата по server_id через getStorageByChatId
        const logData = user.getStorageByLogId(parseInt(server_id));
        if (messageLength !== logData.data.length) {
            console.log('Читаем и обновляем чат')
            setMessageLength(logData.data.length);
            setChatMessages(logData.data); // Устанавливаем сообщения чата
            const scrollToBottom = () => {
                if (chatEndRef.current) {
                    chatEndRef.current.scrollIntoView({behavior: "smooth"});
                }
            };

            scrollToBottom(); // Прокрутка при монтировании
            const timeoutId = setTimeout(scrollToBottom, 100); // Пауза перед прокруткой

            return () => clearTimeout(timeoutId);
        }
    }, [user.log, server_id, messageLength]);

    // Прокручиваем к последнему сообщению при обновлении сообщений
    useEffect(() => {
        const scrollToBottom = () => {
            if (chatEndRef.current) {
                chatEndRef.current.scrollIntoView({ behavior: "smooth" });
            }
        };

        scrollToBottom(); // Прокрутка при монтировании
        const timeoutId = setTimeout(scrollToBottom, 100); // Пауза перед прокруткой

        return () => clearTimeout(timeoutId);
    }, [chatMessages]);

    const sendMessage = async (server_id, message) => {
        try {
            let data = await sendRcon(server_id, message, 'command');
            console.log(data);
        } catch (e) {
            console.log (e.response.data.message);
        }
    }

    const handleSendMessage = () => {
        if (message.trim()) {
            sendMessage(server_id, message).then(r => {
                console.log(r);});
            setMessage("");
        }
    };

    return (
        <Container maxWidth="xl" sx={{display: 'flex', flexDirection: 'column', maxHeight: 'calc(100vh - 70px)', padding: '0'}}>
            <Box sx={{ width: '100%', marginTop: 3 }}>
                <BreadCrumbs hrefs={hrefs} name='Логи сервера' />
            </Box>
            <h1>Логи сервера {server_id}</h1>

            {/* Контейнер для сообщений с прокруткой */}
            <Box
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    border: '1px solid #4f4f4f',
                    padding: '10px',
                    marginBottom: '10px',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#4f4f4f',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#1a1a1a',
                    }
                }}
            >
                {chatMessages && chatMessages.length > 0 ? (
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {chatMessages.map((message, index) => (
                            <li
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    /*justifyContent: 'space-between',
                                    alignItems: 'flex-start',*/
                                    marginBottom: '10px',
                                    /*'@media (max-width: 600px)': {
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    },*/
                                }}
                            >
                                <strong style={{ marginRight: '10px' }}>{new Date(message.date).toLocaleString()}:</strong>
                                <span>{message.message}</span>
                            </li>
                        ))}
                        <div ref={chatEndRef} /> {/* Ссылка на конец списка сообщений */}
                    </ul>
                ) : (
                    <p>Сообщений пока нет</p>
                )}
            </Box>

            {/* Форма для ввода сообщения */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '70px',
                    padding: '10px',
                    borderTop: '1px solid #4f4f4f',
                    backgroundColor: 'rgb(46, 51, 52)'
                }}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Введите сообщение"
                    sx={{ marginRight: '10px' }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendMessage}
                    disabled={!message}
                >
                    Отправить
                </Button>
            </Box>
        </Container>
    );
});

export default Log;
