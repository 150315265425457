import React, {useContext, useEffect, useRef, useState} from "react";
import { Box, Paper, Typography, TextField, Button, List, ListItem, ListItemText, Divider, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {useParams} from "react-router-dom";
import {Context} from "../index";
import {indexServers, sendRcon} from "../http/ServerAPI";
import {observer} from "mobx-react-lite";

const getPlayersLabel = (count) => {
    if (count % 10 === 1 && count % 100 !== 11) return `${count} игрок`;
    if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) return `${count} игрока`;
    return `${count} игроков`;
};

const ChatPage = observer(() => {
    const { server_id } = useParams();
    const { user } = useContext(Context);
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [playerNames, setPlayerNames] = useState([]);
    const [message, setMessage] = useState("");
    const [playerCount, setPlayerCount] = useState(0);
    const [serverName, setServerName] = useState(server_id);
    const [showParticipants, setShowParticipants] = useState(false);
    const [messageLength, setMessageLength] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const chatEndRef = useRef(null);

    const indexServer = async () => {
        try {
            indexServers().then(data => {
                user.setServers(data);
            }).catch(e => {
                console.log(e);
            });
        } catch (error) {
            console.error("Failed to fetch server data:", error);
        }
    };

    useEffect(() => {
        indexServer();
        const intervalId = setInterval(indexServer, 60000);
        return () => clearInterval(intervalId);
    }, [user]);

    useEffect(() => {
        if (user.userName) {
            setName(user.userName);
            setRole(user.role);
        }
    }, [user.userName, user.role]);



    useEffect(() => {
        if (user.servers.length) {
            const server = user.servers?.find(item => item.server_id === parseInt(server_id));
            setServerName(server ? server.title + " [Играет: " + server.online + "]" : server_id);
            setPlayerNames(server.player_names ?? []);
            setPlayerCount(server.online ?? 0)
        }
    }, [user.servers, server_id]);



    useEffect(() => {
        const chatData = user.getStorageByChatId(parseInt(server_id));
        if (messageLength !== chatData.data.length) {
            console.log('Читаем и обновляем чат')
            setMessageLength(chatData.data.length);
            setChatMessages(chatData.data); // Устанавливаем сообщения чата
            const scrollToBottom = () => {
                if (chatEndRef.current) {
                    chatEndRef.current.scrollIntoView({behavior: "smooth"});
                }
            };

            scrollToBottom(); // Прокрутка при монтировании
            const timeoutId = setTimeout(scrollToBottom, 100); // Пауза перед прокруткой

            return () => clearTimeout(timeoutId);
        }
    }, [user.chat, server_id, messageLength]);



    useEffect(() => {
        const scrollToBottom = () => {
            if (chatEndRef.current) {
                chatEndRef.current.scrollIntoView({ behavior: "smooth" });
            }
        };

        scrollToBottom(); // Прокрутка при монтировании
        const timeoutId = setTimeout(scrollToBottom, 100); // Пауза перед прокруткой

        return () => clearTimeout(timeoutId);
    }, [chatMessages]);

    const sendMessage = async (server_id, message) => {
        try {
            let data = await sendRcon(server_id, message, 'chat');
            console.log(data);
        } catch (e) {
            console.log (e.response.data.message);
        }
    }

    const handleSendMessage = () => {
        if (message.trim()) {
            sendMessage(server_id, message).then(r => {
                console.log(r);});
            const newMessage = {
                date: new Date().toISOString(),
                chat_message: name + ' ' + message,
            };
            const newChatData = {
                chat: server_id,
                data: [newMessage]
            };
            user.setChat(newChatData);
            //setChatMessages(prev => [...prev, newMessage]);
            setMessage("");
        }
    };

    const toggleSidebar = (open) => () => {
        setIsSidebarOpen(open);
    };

    return (
        <Box sx={{ display: "flex", height: "calc(100vh - 70px)", overflow: "hidden" }}>

            {/* Chat */}
            <Box
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    bgcolor: "background.default",
                }}
            >
                {/* Mobile Sidebar Toggle Button */}
                <Box sx={{ display: { xs: "flex", md: "none" }, p: 1, bgcolor: "background.paper", justifyContent: "space-between" }}>
                    <Typography variant="h6" sx={{ ml: 1 }}>
                        {serverName}
                    </Typography>
                    <IconButton onClick={toggleSidebar(true)} style={{flexGrow: "inherit"}}>
                        <MenuIcon />
                    </IconButton>
                </Box>

                {/* Messages */}
                <Box sx={{
                    flexGrow: 1, overflowY: "auto", p: 2, '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#4f4f4f',
                        borderRadius: '4px',
                    },
                }}>
                    {chatMessages.length > 0 ? (chatMessages.map((message, index) => (
                        <Paper
                            key={index}
                            sx={{
                                mb: 1,
                                p: 1,
                                wordBreak: "break-word", // Разбиваем длинные слова
                                overflowWrap: "break-word", // Альтернативное свойство для поддержки
                            }}
                        >
                            <strong>{new Date(message.date).toLocaleString()}:</strong>{message.chat_message}
                        </Paper>
                    ))) : (
                        <Paper
                            sx={{
                                mb: 1,
                                p: 1,
                                wordBreak: "break-word", // Разбиваем длинные слова
                                overflowWrap: "break-word", // Альтернативное свойство для поддержки
                            }}
                        >
                            Сообщений пока нет
                        </Paper>
                    )}
                    <div ref={chatEndRef}/>
                </Box>

                {/* Input Form */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        borderTop: 1,
                        borderColor: "divider",
                        p: 2,
                    }}
                >
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder="Введите сообщение..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        sx={{ mr: 2 }}
                    />
                    <Button variant="contained" onClick={handleSendMessage} disabled={!message}>
                        Отправить
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    display: { xs: "none", md: "flex" }, // Скрываем на мобильных устройствах
                    width: "20%",
                    bgcolor: "background.paper",
                    borderLeft: 1,
                    borderColor: "divider",
                    flexDirection: "column",
                }}
            >
                <Typography variant="h6" sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
                    Список чатов
                </Typography>
                <List sx={{ flexGrow: 1, overflowY: "auto" }}>
                    <ListItem button key={serverName}>
                        <ListItemText primary={serverName} />
                    </ListItem>
                </List>
                <Divider />
                <Typography variant="h6" sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
                    Участники
                </Typography>
                <List sx={{ flexGrow: 1, overflowY: "auto" }}>
                    {playerNames.map((user) => (
                        <ListItem key={user}>
                            <ListItemText primary={user} />
                        </ListItem>
                    ))}
                </List>
            </Box>

            {/* Sidebar (Mobile View) */}
            <SwipeableDrawer
                anchor="right"
                open={isSidebarOpen}
                onClose={toggleSidebar(false)}
                onOpen={toggleSidebar(true)}

            >
                <Box
                    sx={{
                        width: 250,
                        bgcolor: "background.paper",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography variant="h6" sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
                        Список чатов
                    </Typography>
                    <List sx={{ flexGrow: 1, overflowY: "auto" }}>
                        <ListItem button key={serverName}>
                            <ListItemText primary={serverName} />
                        </ListItem>
                    </List>
                    <Divider />
                    <Typography variant="h6" sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
                        Участники
                    </Typography>
                    <List sx={{ flexGrow: 1, overflowY: "auto" }}>
                        {playerNames.map((user) => (
                            <ListItem key={user}>
                                <ListItemText primary={user} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </SwipeableDrawer>
        </Box>
    );
});

export default ChatPage;
