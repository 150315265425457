import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {confirmation} from "../http/userAPI";
import {Box, Button, Typography} from "@mui/material";
import {HOME_ROUTE} from "../utils/consts";

const EmailConfirmation = () => {
    const { hash } = useParams();
    const navigate = useNavigate();
    const [emailConfirmationMessage, setEmailConfirmationMessage] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (hash !== '') {
            confirmation(hash).then(data => {
                console.log(data);
                setStatus(data.status);
                setEmailConfirmationMessage(true);
            }).catch(e => {
                console.log(e);
            });
        }
    }, [hash, navigate]);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h2>Подтверждение email...</h2>
            <p>Пожалуйста, подождите, пока мы обрабатываем вашу ссылку.</p>
            {emailConfirmationMessage && (
                <Box
                    sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "rgba(0, 0, 0, 0.8)",
                        zIndex: 1500,
                    }}
                >
                    <Box
                        sx={{
                            width: isMobile ? "90%" : 400,
                            bgcolor: "background.paper",
                            p: 4,
                            borderRadius: 2,
                            textAlign: "center",
                            boxShadow: 6,
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Подтверждение email
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {status ? 'Ваш email успешно подтвержден' : 'Произошла ошибка при подтверждении email, возможно ссылка недействительна'}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setEmailConfirmationMessage(false);
                                navigate(HOME_ROUTE);
                            }}
                            sx={{ mt: 2 }}
                        >
                            Закрыть
                        </Button>
                    </Box>
                </Box>
            )}
        </div>
    );
}

export default EmailConfirmation;