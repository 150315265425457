import React, { useContext, useState, useEffect, useRef } from "react";
import BreadCrumbs from "../components/Breadcrumbs";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Container from "@mui/material/Container";
import { Context } from "..";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { SERVERS_ROUTE } from "../utils/consts";
import {indexServers, jobServer, sendRcon, serversInfo} from "../http/ServerAPI";

const getPlayersLabel = (count) => {
    if (count % 10 === 1 && count % 100 !== 11) return `${count} игрок`;
    if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) return `${count} игрока`;
    return `${count} игроков`;
};

const Chat = observer(() => {
    const { server_id } = useParams();
    const { user } = useContext(Context);
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [playerNames, setPlayerNames] = useState([]);
    const [message, setMessage] = useState("");
    const [playerCount, setPlayerCount] = useState(0);
    const [serverName, setServerName] = useState(server_id);
    const [showParticipants, setShowParticipants] = useState(false);
    const [messageLength, setMessageLength] = useState(0);
    const chatEndRef = useRef(null);

    const indexServer = async () => {
        try {
            indexServers().then(data => {
                user.setServers(data);
            }).catch(e => {
                console.log(e);
            });
        } catch (error) {
            console.error("Failed to fetch server data:", error);
        }
    };

    useEffect(() => {
        indexServer();
        const intervalId = setInterval(indexServer, 60000);
        return () => clearInterval(intervalId);
    }, [user]);

    useEffect(() => {
        if (user.userName) {
            setName(user.userName);
            setRole(user.role);
        }
    }, [user.userName, user.role]);



    useEffect(() => {
        if (user.servers.length) {
            const server = user.servers?.find(item => item.server_id === parseInt(server_id));
            setServerName(server ? server.title : server_id);
            setPlayerNames(server.player_names ?? []);
            setPlayerCount(server.online ?? 0)
        }
    }, [user.servers, server_id]);



    useEffect(() => {
        const chatData = user.getStorageByChatId(parseInt(server_id));
        if (messageLength !== chatData.data.length) {
            console.log('Читаем и обновляем чат')
            setMessageLength(chatData.data.length);
            setChatMessages(chatData.data); // Устанавливаем сообщения чата
            const scrollToBottom = () => {
                if (chatEndRef.current) {
                    chatEndRef.current.scrollIntoView({behavior: "smooth"});
                }
            };

            scrollToBottom(); // Прокрутка при монтировании
            const timeoutId = setTimeout(scrollToBottom, 100); // Пауза перед прокруткой

            return () => clearTimeout(timeoutId);
        }
    }, [user.chat, server_id, messageLength]);



    useEffect(() => {
        const scrollToBottom = () => {
            if (chatEndRef.current) {
                chatEndRef.current.scrollIntoView({ behavior: "smooth" });
            }
        };

        scrollToBottom(); // Прокрутка при монтировании
        const timeoutId = setTimeout(scrollToBottom, 100); // Пауза перед прокруткой

        return () => clearTimeout(timeoutId);
    }, [chatMessages]);

    const sendMessage = async (server_id, message) => {
        try {
            let data = await sendRcon(server_id, message, 'chat');
            console.log(data);
        } catch (e) {
            console.log (e.response.data.message);
        }
    }

    const handleSendMessage = () => {
        if (message.trim()) {
            sendMessage(server_id, message).then(r => {
                console.log(r);});
            const newMessage = {
                date: new Date().toISOString(),
                chat_message: name + ' ' + message,
            };
            const newChatData = {
                chat: server_id,
                data: [newMessage]
            };
            user.setChat(newChatData);
            //setChatMessages(prev => [...prev, newMessage]);
            setMessage("");
        }
    };



    return (
        <Container maxWidth="xl" sx={{ display: 'block', padding: '0' }}>

            <Box sx={{ width: '100%', marginTop: 3, marginBottom: 1 }}>
                <BreadCrumbs hrefs='' name='Чат сервера' />
            </Box>
            <Box sx={{ marginBottom: 1 }}>
                <Typography variant="h6" onClick={() => setShowParticipants(prev => !prev)} style={{ cursor: 'pointer' }}>
                    {serverName} [Играет: {playerCount}]
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', height: 'calc(100vh - 170px)' }}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        display: { xs: !showParticipants ? 'flex' : 'none', sm: 'flex' },
                        flexDirection: 'column',
                        flexGrow: 1,
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            maxWidth: '1180px',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            border: '1px solid #4f4f4f',
                            padding: '10px',
                            marginBottom: '10px',
                            flex: 1,
                            '&::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#4f4f4f',
                                borderRadius: '4px',
                            },
                        }}
                    >
                        {chatMessages.length > 0 ? (
                            <ul style={{
                                listStyleType: 'none',
                                padding: 0,
                                overflowX: 'hidden',
                                wordWrap: 'break-all', // Для старых браузеров
                                overflowWrap: 'break-all'
                            }}>
                                {chatMessages.map((msg, index) => (
                                    <li key={index} style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginBottom: '10px'
                                    }}>
                                        <strong>{new Date(msg.date).toLocaleString()}:</strong>
                                        <span style={{
                                            wordWrap: 'break-all', // Для старых браузеров
                                            overflowWrap: 'break-all'
                                        }}>{msg.chat_message}</span>
                                    </li>
                                ))}
                                <div ref={chatEndRef} />
                            </ul>
                        ) : (
                            <p>Сообщений пока нет</p>
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '70px',
                            padding: '10px',
                            borderTop: '1px solid #4f4f4f',
                            backgroundColor: 'rgb(46, 51, 52)',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Введите сообщение"
                            sx={{ marginRight: '10px' }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSendMessage}
                            disabled={!message}
                        >
                            Отправить
                        </Button>
                    </Box>
                </Box>
            </Box>

            {/* Блок с информацией о сервере */}
            <Box
                sx={{
                    flexBasis: { xs: '100%', sm: '300px' },
                    padding: '10px',
                    borderLeft: '1px solid #4f4f4f',
                    display: { xs: showParticipants ? 'block' : 'none', sm: 'block' },
                }}
            >
                <Box>
                    <Typography variant="body1">Админы</Typography>
                    <ul style={{ listStyleType: 'none', paddingInlineStart: '10px'}}>
                        <li key="0">{name}</li>
                    </ul>
                {/* Placeholder for participants */}
                </Box>
                <Box sx={{ marginTop: 2 }}>
                    <Typography variant="subtitle1">VIP:</Typography>
                    {/* Замени следующий массив на реальный список участников */}
                    {/*<ul>
                        {user.participants && user.participants.map((participant, index) => (
                            <li key={index}>{participant.name} ({participant.role})</li>
                        ))}
                    </ul>*/}
                </Box>
                <Box sx={{ marginTop: 2 }}>
                    <Typography variant="subtitle1">Игроки:</Typography>
                    {/* Замени следующий массив на реальный список участников */}
                    <ul style={{ listStyleType: 'none', paddingInlineStart: '10px'}}>
                        {playerNames && playerNames.map((participant, index) => (
                            <li key={index}>{participant}</li>
                        ))}
                    </ul>
                </Box>
                <Box sx={{ marginTop: 2 }}>
                    <Typography variant="subtitle1">Гости:</Typography>
                    {/* Замени следующий массив на реальный список участников */}
                    {/*<ul>
                        {user.participants && user.participants.map((participant, index) => (
                            <li key={index}>{participant.name} ({participant.role})</li>
                        ))}
                    </ul>*/}
                </Box>
            </Box>
            </Box>
        </Container>
    );
});

export default Chat;
