import React, {useContext, useEffect, useState} from "react";
import AppRouter from "./components/AppRouter";
import {BrowserRouter as Router} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {check, userInfo} from "./http/userAPI";
import {Context} from ".";
import jwt_decode from "jwt-decode";
import HomeHeader from "./components/HomeHeader";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";
import WsAPI from './http/wsAPI';
import WsUserAPI from "./http/wsUserAPI";

const App = observer(() => {
    const {user} = useContext(Context);
    const [loading,setLoading] = useState(true);
    const [exp, setExp] = useState(0);
    const [decoded, setDecoded] = useState(null);
    const timestamp = Math.ceil((Date.now()) / 1000);


    useEffect(() => {
        if(user.user.access_token) {
            setDecoded(jwt_decode(user.user.access_token));
        }else{
            refreshToken();
        }
    }, [user.user]);

    useEffect(() => {
        if(exp && timestamp >= exp){
            refreshToken();
        }
    }, [timestamp]);

    useEffect(() => {
        if(decoded) {
            user.setIsAuth(true);
            user.setUserName(decoded.name);
            user.setRole(decoded.role);
            setExp(decoded.exp);
            userInfo().then(data => {
                console.log(data);
                user.setUserInfo(data)
            }).catch(e => {
                console.log(e.response)
            });
        }
    }, [decoded]);

    const refreshToken = () => {
        check().then(data => {
            user.setUser(data);
            if(user.user.access_token) {
                setDecoded(jwt_decode(user.user.access_token));
            }
        }).catch(e => {
            if(e.response.status === 401) {
                user.setIsAuth(false);
                user.setUserName('');
                user.setUser({});
            }
        });

    }

    const theme = createTheme({
        palette: {
            mode: "dark",
            primary: {
                main: '#00c58a',
                contrastText: '#ffffff'
            },
            secondary: {
                main: '#ff5722',
            },
            background: {
                default: '#2e3334',
                paper: '#384042'
            }
            // whatever colors you want to include
            // Please refer to the following for more details
            // https://mui.com/customization/default-theme/#explore
        },
        // Here you could add global styles what you exactly want
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        background: "#363f41"
                    }
                }
            },
            typography: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        //fontFamily: myFontFamily,
                        // regarding the code you posted, there is no props like fontFamily, so you can't add it to the defaultProps, you can look into the Typography APIs
                    },
                },
                chip: {
                    root: {
                        backgroundColor: '#ffffff'
                        // color props should work color="primary" and be applied the primary color #000
                    }
                }
            }
        }
    });


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {user.isAuth && <WsUserAPI />}
            {user.userInfo && user.userInfo.role === 'admin' && <WsAPI />}
            <Router>
                <HomeHeader/>
                <AppRouter/>
            </Router>
        </ThemeProvider>

  );
});

export default App;
